<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { postAction, getAction, putAction } from '@/command/netTool'
import { Message } from 'ant-design-vue'
export default {
  name: 'ticketSaleGate',
  data() {
    return {
      ...api.command.getState(),
      selectedArr: [],
      selectedArr2: [],
      roundData: {
        machineList: [],
      },
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmTicketCheckPoint/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'name',
        },
        {
          name: '景点名称',
          type: 'input',
          key: 'scenicName',
        },
      ]
    },
    getDrawerForm(data) {
      return [
        {
          form: [
            {
              name: '景点名称',
              type: 'text',
              key: 'scenicName',
              labelCol: { span: 3 },
              wrapperCol: { span: 21 },
            },
            {
              name: '选择闸机',
              type: 'table',
              labelCol: { span: 3 },
              wrapperCol: { span: 24 },
              dataSource: this.roundData.machineList,
              showFootButton: false,
              showRowSelect: true,
              showPagination: false,
              bordered: true,
              rowKey: 'id',
              selectedRowKeys: this.selectedArr,
              selectedRows: this.selectedArr2,
              rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                  console.log('selectedRowKeys, selectedRows', selectedRowKeys, selectedRows)
                  this.selectedArr = selectedRowKeys
                  this.selectedArr2 = selectedRows
                },
              },
              columns: [
                {
                  dataIndex: 'gateName',
                  align: 'left',
                  title: '闸机名称',
                  width: '0%',
                },
                {
                  dataIndex: 'deviceNo',
                  align: 'left',
                  title: '设备编号',
                  width: '0%',
                },
                {
                  dataIndex: 'ipAddress',
                  align: 'left',
                  title: 'IP地址',
                  width: '0%',
                },
                {
                  dataIndex: 'gateType',
                  title: '类型',
                  align: 'left',
                  width: '0%',
                  customRender: function (text, records) {
                    return {
                      0: '检票通道',
                      1: '进口上报',
                      2: '出口上报',
                    }[text]
                  },
                },
                {
                  dataIndex: 'remark',
                  align: 'left',
                  title: '备注',
                  width: '150px',
                },
              ],
            },
          ],
        },
      ]
    },
    rowSessionClick(row) {
      this.selectedArr = []
      this.selectedArr2 = []
      getAction(`/farmTicketGate/list?checkPointId=${row.id}`).then((res) => {
        this.roundData = {
          machineList: res.data || [],
          scenicName: row.scenicName,
          checkPointId: row.id,
        }
        if (this.roundData.machineList && this.roundData.machineList.length > 0) {
          this.selectedArr2 = this.roundData.machineList.filter((e) => e.isChecked == '1')
          this.selectedArr = this.roundData.machineList.filter((e) => e.isChecked == '1').map((obj) => obj.id)
        }
        this.showRoundDrawer()
      })
    },
    showRoundDrawer() {
      apiTool.showDrawer({
        title: '闸机管理',
        width: '800px',
        view: DrawerForm,
        viewProps: {
          data: (data) => this.getDrawerForm(data),
          form: {
            ...this.roundData,
          },
        },
        success: ({ data, setHidden }) => {
          // if (this.selectedArr.length == 0) {
          //   Message.error('请先选择场次!')
          //   return
          // }
          console.log('data', this.selectedArr)
          // return
          putAction('/farmTicketCheckPoint/relationGate', {
            checkPointId: data.checkPointId,
            gateIds: this.selectedArr.length == 0 ? '' : this.selectedArr.join(','),
          }).then((res) => {
            Message.success('操作成功')
            setHidden()
            this.getInit()
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'scenicName',
          title: '所属景点',
          align: 'left',
          sorter: (a, b) => a.scenicName.length - b.scenicName.length,
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
        },
        {
          dataIndex: 'location',
          title: '位置',
          align: 'left',
        },
        {
          dataIndex: 'type',
          title: '类型',
          align: 'left',
          customRender: function (text, records) {
            return {
              0: '检票点',
              1: '售票点',
            }[text]
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'remark',
          title: '备注',
          align: 'left',
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                dispaly: true,
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/farmTicketCheckPoint/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '闸机',
                dispaly: true,
                onClick: () => {
                  this.rowSessionClick(records)
                },
              },
              {
                name: '编辑',
                dispaly: true,
                onClick: () => this.$router.push(`/commodityManagement/ticketSaleGateDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                dispaly: records.upDown == 1,
                popTitle: '是否确认删除?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmTicketCheckPoint?id=${records.id}`,
                  }),
              },
            ].filter((e) => e.dispaly)
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/ticketSaleGateDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: '/farmTicketCheckPoint/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketCheckPoint/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketCheckPoint/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
